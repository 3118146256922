import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/app.scss";
import "./style/media_query.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Products from "./pages/Products";
import Cart from "./pages/Cart";
import ProductDetails from "./pages/ProductDetails";
import Checkout from "./pages/Checkout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import ExchangePolicy from "./pages/ExchangePolicy";
import NotFound from "./pages/NotFound";
import PrivateRoutePage from "./pages/PrivateRoutePage";

// any link to click than render to top
function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}

const App = () => {
  return (
    <>
      <div className="app">
        <ToastContainer />
        <BrowserRouter>
          <ScrollToTop />
          <Header />
          <div className="app-body">
            <Routes>
              <Route path="/" element={<Products />} />
              <Route path="/product/:id" element={<ProductDetails />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-&-conditions" element={<TermsAndConditions />} />
              <Route path="/exchange-policy" element={<ExchangePolicy />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/private/page/paid-order/all/orders" element={<PrivateRoutePage />} />
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;
