import React from "react";
import MetaDescTag from "../../components/MetaDescTag";

const ExchangePolicy = () => {
  return (
    <>
      <MetaDescTag
        title={`EXCHANGE POLICY | EthnicINDI`}
        description={`ethnicindi.com Offers 7-Days Exchange Policy to Our Customers. You Can Conveniently Exchange Any Item Within 7 Days from The Date of Delivery. Please Do Not Accept Any Order If Seal Is Broken/Unsealed at The Time of Delivery. All Returned Items Must Be Unused and Unwashed for Hygiene Reasons And Returned with Original Packaging and Tags in Place. Items Without Tags Will Not Be Accepted.`}
      />
      <section className="py-5 return-exchange-page">
        <div className="our-container">
          <div className="inner-container mx-auto">
            <div className="return-exchange-page-heading">
              <h1 className="fs-2 fw-medium text-center text-capitalize mb-4">
                EXCHANGE POLICY
              </h1>
            </div>
            <div className="return-exchange-page-body">
              <p className="fs-6 mb-2 text-capitalize">
                <span className="text-lowercase">ethnicindi.com</span> Offers
                7-Days Exchange Policy to Our Customers. You Can Conveniently
                Exchange Any Item Within 7 Days from The Date of Delivery.
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                Please Do Not Accept Any Order If Seal Is Broken/Unsealed at The
                Time of Delivery.
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                All Returned Items Must Be Unused and Unwashed for Hygiene
                Reasons And Returned with Original Packaging and Tags in Place.
                Items Without Tags Will Not Be Accepted.
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                All Items Purchased from{" "}
                <span className="text-lowercase">ethnicindi.com</span> Can Be
                Exchange Within 48 Hours from The Date of Delivery (As Recorded
                by The Shipping Provider).
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                The Customer Will Receive an Amount to Their Bank Account Within
                7 Days.
              </p>
              <p className="fs-6 mb-2 fw-semibold text-capitalize">
                Note: The Shipping Charge Is Not Refundable.
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                Once We Receive the Returned Products, A Quality Check Will Be
                Performed on The Items by Our Quality Team. This Is Subject to
                The Return Having Met the Following Requirements:
              </p>
              <ul>
                <li>
                  The Items Should Be Unused and Unwashed for Hygiene Reasons.
                </li>
                <li>
                  The Product Should Have the Original Packaging and Tags in
                  Place. Items Without the Original Tags Will Not Be Accepted.
                </li>
                <li>
                  They Should Be Exchange Within 7 Days from The Delivery Date.
                </li>
                <li>
                  In Case You Receive A Damaged/Defective,{" "}
                  <span className="text-lowercase">ethnicindi.com</span> Should
                  Be Notified Within 24 Hours of Receipt. We May Request You to
                  Share Snap Shot At{" "}
                  <span className="text-lowercase">
                    karnmarketingwarfarellp@gmail.com
                  </span>
                </li>
                <li>
                  Any Returned Item Received by Us That Does Not Meet the
                  Above-Mentioned Conditions Will Not Be Accepted, And Will Be
                  Returned to The Customer at Their Expense. No Amount Will Be
                  Reimbursed in This Case.
                </li>
                <li>
                  A Notification with The Status of The Return Will Be Sent to
                  The Customer Within 48 Working Hours from The Date of
                  Receiving the Items.
                </li>
              </ul>
              <p className="fs-6 mb-2 fw-semibold text-capitalize">
                Terms & Conditions
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                This Exchange Policy Applies to All Items Purchased Directly
                from
                <span className="text-lowercase"> ethnicindi.com</span> (With
                the Exception of Innerwear & Any Freebies). The Policy Is
                Subject to Change. Hence, Please Visit Our Website
                <span className="text-lowercase">ethnicindi.com</span> for The
                Latest Policy.
              </p>
              <p className="fs-6 mb-0 fw-semibold text-capitalize">
                The Exchange Process
              </p>
              <p className="fs-6 mb-2 fw-semibold text-capitalize">
                Online Exchange
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                You Can Exchange an Item Online at{" "}
                <span className="text-lowercase">ethnicindi.com</span> By
                Following the Below Steps.
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                Log In To The 'My Account' Section At{" "}
                <span className="text-lowercase">ethnicindi.com</span>
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                Choose the Order That Needs Modification.
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                Select the Product to Exchange and Fill in The Required
                Information.
              </p>
              <h2 className="fs-6 mb-2 fw-semibold text-capitalize">
                Customer Support
              </h2>
              <p className="fs-6 mb-2 text-capitalize">
                For Any Other Queries, You Can Speak to Our Customer Care Team
                Either by Calling On 7304675307Or By E-Mailing At
                karnmarketingwarfarellp@gmail.com
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                Please Have the Below Information Ready Before You Call Customer
                Care or Mention the Same in The Email:
              </p>
              <ul>
                <li>Order Number / Registered E-Mail Id.</li>
                <li>Product Name.</li>
                <li>Pick-Up Address.</li>
              </ul>
              <p className="fs-6 mb-2 text-capitalize">
                To Process A Exchange Successfully, Please Re-Pack the Items to
                Be Exchange with All the Original Packaging & Tags That They
                Were Shipped With.
              </p>
              <p className="fs-6 mb-2 fw-semibold text-capitalize">
                Exchange Policy
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                We regret to inform you that we cannot guarantee exchanges in
                the event of product unavailability. However, we offer
                alternatives such as a credit note, selecting another item of
                equal value, or providing a refund.
              </p>
              <p className="fs-6 mb-2 fw-semibold text-capitalize">
                Explanation
              </p>
              <p className="fs-6 mb-2 text-capitalize">
                This sentence communicates the company's policy regarding
                exchanges when a product is out of stock. It acknowledges the
                limitation of not being able to promise exchanges due to product
                availability issues. However, it reassures the customer by
                offering alternative solutions to accommodate their needs. These
                alternatives include providing a credit note, allowing the
                customer to select another item of equal value, or providing a
                refund. This ensures that even if the desired product is
                unavailable, the customer still has options to make the most of
                their purchase or seek a refund if preferred.
              </p>
              <p className="fs-6 mb-2 fw-semibold text-capitalize">
                *Please read the Exchange Policy Carefully Before Returning an
                Item.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExchangePolicy;
