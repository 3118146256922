import React from "react";
import { Link } from "react-router-dom";
import { FaIndianRupeeSign } from "react-icons/fa6"; // For price display

const Product = ({ product }) => {
  return (
    <Link
      to={`/product/${product.id}`} // Dynamic route to product details
      className="text-decoration-none product-link d-flex flex-column p-2 product-row-block"
    >
      <img src={product.image} alt={product.title} className="product-image" /> 
      <div className="product-raw-block-details">
        <div className="h5 product-color text-capitalize">{product.title}</div>
        <div className="d-flex align-items-center mb-3 gap-2 product-raw-block-details-price">
          <FaIndianRupeeSign className="fs-6 fw-medium mb-0" />
          {/* <div className="h3 fs-6 fw-medium mb-0"><del>{product.price}</del></div> */}
          <div className="h3 fs-6 fw-medium mb-0">{product.sellingPrice}</div>
        </div>
      </div>
    </Link>
  );
};

export default Product;
