import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FaFacebook, FaSquareInstagram } from "react-icons/fa6";
import { footerNavLinks } from "../../data";

const Footer = () => {
  return (
    <>
      <footer className="py-3">
        <div className="our-container">
          <div className="inner-container mx-auto px-5">
            <div className="pb-1 footer-nav-links">
              <div className="mb-3 d-flex align-items-center justify-content-center gap-4 fn-link">
                {footerNavLinks.map((item, index) => {
                  return (
                    <div key={index}>
                      <NavLink
                        to={item.webUrl}
                        className="fs-6 fn-item"
                        key={index}
                      >
                        {item.title}
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="py-2 main-footer row">
              <div className="footer-address my-4 col-12 col-md-12 col-lg-4">
                <p className="m-0 text-justify">
                  Welcome to EthnicINDI, where culture meets elegance. 'Ethnic'
                  reflects global culture, and 'INDI' honors India. Our
                  peacock-inspired designs celebrate beauty and heritage.
                </p>
                <div className="d-flex align-items-center justify-content-start gap-3 mt-2 footer-social-links">
                  <Link
                    to={
                      "https://www.facebook.com/people/Ethnicindi/61552992852504/?mibextid=ZbWKwL"
                    }
                    target="_blank"
                    className="fs-link"
                  >
                    <FaFacebook className="fs-6 d-flex align-items-center justify-content-center fs-link-item" />
                  </Link>
                  <Link
                    to={
                      "https://www.instagram.com/ethnicindi_/?utm_source=qr&igsh=YjkwN2J4cXl2bGVj"
                    }
                    target="_blank"
                    className="fs-link"
                  >
                    <FaSquareInstagram className="fs-6 d-flex align-items-center justify-content-center fs-link-item" />
                  </Link>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center my-4 footer-logo col-12 col-md-12 col-lg-4">
                <Link to={"/"} className="d-block">
                  <img src="/images/ethinic-logo-with-title.png" alt="" />
                </Link>
                <div>
                  <p className="mb-1">
                    ©{new Date().getFullYear()} ethnicindi. All Rights Reserved
                  </p>
                </div>
              </div>
              <div className="footer-links col-12 col-md-12 col-lg-4">
                <div>
                  <p className="mb-0">
                    <strong>Address : </strong>
                    <Link
                      to={
                        "https://www.google.com/maps?ll=19.114862,73.005521&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=8289053274308515687"
                      }
                      target="_blank"
                      className="fu-address-link text-justify"
                    >
                      Office no 7, Ambe Prerna Cooperative Housing Society, Plot
                      no 8, Near Central Park, Sector 3, Ghansoli, Navi Mumbai,
                      Thane - 400701
                    </Link>
                  </p>
                </div>
                <div>
                  <p className="mb-0">
                    <strong>Email : </strong>
                    <Link
                      to={"mailto:ethnicindi@gmail.com"}
                      className="fu-address-link"
                    >
                      ethnicindi@gmail.com
                    </Link>
                  </p>
                </div>
                <div>
                  <p className="mb-0">
                    <strong>Mobile : </strong>
                    <Link to={"tel:7304191806"} className="fu-address-link">
                      7304191806
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

// import React from "react";
// import { Link, NavLink } from "react-router-dom";
// import { FaFacebook, FaSquareInstagram, FaYoutube } from "react-icons/fa6";
// // import { footerNavLinks } from "../../data";

// const Footer = () => {
//   return (
//     <>
//       <footer className="py-3">
//         <div className="our-container">
//           <div className="inner-container mx-auto px-5">
//             <span className="main-footer mt-4">
//               <div className="main-sub-footer">
//                 <span className="info-footer">
//                   <p className="fs-5">Nav Links</p>
//                   <div className="info-footer-sublinks">
//                     <Link to={"/"} className="pricavy-policy">
//                       Products
//                     </Link>
//                     <Link to={"/cart"} className="pricavy-policy">
//                       Cart
//                     </Link>
//                     <Link to={"/privacy-policy"} className="pricavy-policy">
//                       Privacy Policy
//                     </Link>
//                   </div>
//                 </span>
//                 <div>
//                   <div className="mb-4 footer-logo">
//                     <Link to={"/"} className="d-block">
//                       <img src="/images/ethinic-logo-with-title.png" alt="" />
//                     </Link>
//                   </div>
//                   <div className="footer-links">
//                     <div>
//                       <p className="mb-0">
//                         ©{new Date().getFullYear()} ethnicindi. All Rights
//                         Reserved
//                       </p>
//                     </div>
//                   </div>
//                   <div className="gap-3 footer-social-links">
//                     <Link
//                       to={
//                         "https://www.facebook.com/people/Ethnicindi/61552992852504/?mibextid=ZbWKwL"
//                       }
//                       target="_blank"
//                       className="fs-link"
//                     >
//                       <FaFacebook className="fs-6 d-flex align-items-center justify-content-center fs-link-item" />
//                     </Link>
//                     <Link
//                       to={
//                         "https://www.instagram.com/ethnicindi_/?utm_source=qr&igsh=YjkwN2J4cXl2bGVj"
//                       }
//                       target="_blank"
//                       className="fs-link"
//                     >
//                       <FaSquareInstagram className="fs-6 d-flex align-items-center justify-content-center fs-link-item" />
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//               <span className="info-more-footer">
//                 <p className="fs-5">Informations</p>
//                 <div>
//                   <p className="mb-0">
//                     <strong>Address </strong>
//                     <div className="social-info">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam vel assumenda cumque doloribus nam amet laboriosam commodi enim perspiciatis veniam.</div>
//                   </p>
//                 </div>
//                 <div>
//                   <p className="mb-0">
//                     <strong>Email </strong>
//                     <div className="social-info">ethinicindi@gmail.com</div>
//                   </p>
//                 </div>
//                 <div>
//                   <p className="mb-0">
//                     <strong>Mobile </strong>
//                     <div className="social-info">+7304191806</div>
//                   </p>
//                 </div>
//               </span>
//             </span>
//           </div>
//         </div>
//       </footer>
//     </>
//   );
// };

// export default Footer;
