import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { MdKeyboardArrowLeft,MdArrowBackIos } from "react-icons/md";
import { useCart } from "../../contexts/CartContext";

const Cart = () => {
  const { cartItems, removeFromCart } = useCart(); // Get cartItems and remove function from context
  const navigate = useNavigate();

  // Function to calculate the total price of the items in the cart
  const getTotalPrice = () => {
    return cartItems.reduce((total, item) => total + item.price, 0).toFixed(2);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <section className="cart-page">
      <div className="our-container">
        <div className="inner-container">
          <div className="cart-page-block py-5">
            <div className="cart-page-heading">
              <h1>Your Shopping Cart</h1>
            </div>
            <button
              onClick={handleBackClick}
              className="back-btn-per-page mb-3"
            >
              <MdArrowBackIos className="back-icon-per-page" />
              Back
            </button>
            <div className="cart-page-body">
              {cartItems.length === 0 ? (
                <div className="alert alert-info cart-alert-box" role="alert">
                  Your cart is empty.{" "}
                  <Link to="/" className="cart-alert-box-link">
                    Shop now!
                  </Link>
                </div>
              ) : (
                <>
                  <ul className="list-group">
                    {cartItems.map((item, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between"
                      >
                        <div className="cart-detail-list-item d-flex w-100">
                          <div className="left-side-cart-img">
                            <Link
                              to={`/product/${item.id}`}
                              className="cart-detail-list-item-link"
                            >
                              <img src={item.imagePath} alt={item.title} />
                            </Link>
                          </div>
                          <div className="right-side-cart-content ms-3 w-100">
                            <div className="right-cart-heading">
                              <Link
                                to={`/product/${item.id}`}
                                className="cart-detail-list-item-link"
                              >
                                <div className="h3 fw-medium">{item.title}</div>
                              </Link>
                              <div className="h5 mb-0 fw-bold">
                                <FaIndianRupeeSign className="cart-rupees-sign" />
                                {item.price}
                              </div>
                            </div>
                            <div><strong>Size :{" "}</strong> <span style={{fontFamily:"CalibreRegular"}}>{item.size}</span> </div>
                            <div className="d-flex align-items-center">
                              <strong>Color :{" "}</strong>
                              <span
                                style={{
                                  display: "inline-block",
                                  width: "14px",
                                  height: "14px",
                                  borderRadius: "50%",
                                  backgroundColor: item.color,
                                  marginLeft: "5px",
                                }}
                              />
                            </div>
                            <div className="right-cart-footer">
                              <div
                                className="cart-trash-btn ms-0"
                              >
                                <button
                                  onClick={() => removeFromCart(index)}
                                  className="faTrash-btn"
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="d-flex mt-5 mb-3 cart-sub-total">
                    <div className="h4 mb-0 fw-bold">
                      <span className="fw-normal" style={{fontFamily:"CalibreRegular"}}>
                        Subtotal ({cartItems.length} items) :{" "}
                      </span>
                      <FaIndianRupeeSign className="subtotal-rupees-sign mb-2" />
                      {getTotalPrice()} {/* Call the getTotalPrice function here */}
                    </div>
                    <div className="cart-footer-btn-group">
                      <Link
                        to={{
                          pathname: "/checkout",
                          state: { cartItems },
                        }}
                        className="cart-sub-total-btn"
                      >
                        Proceed to Buy
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <span>
                      <Link
                        to={"/"}
                        className="cart-continue-shopping-link"
                      >
                        <MdKeyboardArrowLeft className="left-arrow-cart-cs" />
                        <p className="mb-0">Continue shopping</p>
                      </Link>
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cart;












// import React from "react";
// import { Link } from "react-router-dom";
// import { FaIndianRupeeSign } from "react-icons/fa6";
// import { MdKeyboardArrowLeft } from "react-icons/md";
// import { useCart } from "../../contexts/CartContext";

// const Cart = () => {
//   const { cartItems, removeFromCart } = useCart(); // Get cartItems and remove function from context

//   console.log(cartItems);

//   return (
//     <section className="cart-page">
//       <div className="our-container">
//         <div className="inner-container">
//           <div className="cart-page-block py-5">
//             <div className="cart-page-heading">
//               <h1>Your Shopping Cart</h1>
//             </div>
//             <div className="cart-page-body">
//             {cartItems.length === 0 ? (
//                 <div className="alert alert-info cart-alert-box" role="alert">
//                   Your cart is empty.{" "}
//                   <Link
//                     to="/product"
//                     className="cart-alert-box-link"
//                   >
//                     Shop now!
//                   </Link>
//                 </div>
//               ) : (
//                 <>
//                 <ul className="list-group">
//                 {cartItems.map((item, index) => (
//                     <li
//                       key={index}
//                       className="list-group-item d-flex justify-content-between"
//                     >
//                       <div className="cart-detail-list-item d-flex w-100">
//                         <div className="left-side-cart-img">
//                           <Link
//                             to={`/product/${item.id}`}
//                             className="cart-detail-list-item-link"
//                           >
//                             <img src={item.imagePath} alt={item.title} />
//                           </Link>
//                         </div>
//                         <div className="right-side-cart-content ms-3 w-100">
//                           <div className="right-cart-heading">
//                             <Link
//                               to={`/product/${item.id}`}
//                               className="cart-detail-list-item-link"
//                             >
//                               <div className="h3 fw-medium">{item.title}</div>
//                             </Link>
//                             <div className="h5 mb-0 fw-bold">
//                               <FaIndianRupeeSign className="cart-rupees-sign" />
//                               {item.price}
//                             </div>
//                           </div>
//                           <div>Size : {item.size}</div>
//                           <div className="d-flex align-items-center">
//                             Color : <span
//                               style={{
//                                 display: "inline-block",
//                                 width: "14px",
//                                 height: "14px",
//                                 borderRadius: "50%",
//                                 backgroundColor: item.color,
//                                 marginLeft: "5px",
//                               }}
//                             />
//                           </div>
//                           <div className="right-cart-footer">
//                             <div
//                               className="cart-trash-btn ms-0"
//                               style={{ border: 0 }}
//                             >
//                               <button
//                                 onClick={() => removeFromCart(index)}
//                                 className="faTrash-btn"
//                               >
//                                 Delete
//                               </button>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </li>
//                   ))}
//               </ul>
//                   <div className="d-flex mt-5 mb-3 cart-sub-total">
//                     <div className="h4 mb-0 fw-bold">
//                       <span className="fw-normal">
//                         Subtotal ({cartItems.length} items) :{" "}
//                       </span>
//                       <FaIndianRupeeSign className="subtotal-rupees-sign mb-2" />
//                       {getTotalPrice()}
//                     </div>
//                     <div className="cart-footer-btn-group">
//                       {/* {cartItems.length > 1 && (
//                         <button
//                           onClick={clearCart}
//                           className="cart-sub-total-btn"
//                         >
//                           Clear Cart
//                         </button>
//                       )} */}
//                       <Link
//                         to={{
//                           pathname: "/checkout",
//                           state: { cartItems },
//                         }}
//                         className="cart-sub-total-btn"
//                       >
//                         Proceed to Buy
//                       </Link>
//                     </div>
//                   </div>
//                   <div className="d-flex align-items-center justify-content-end">
//                     <span>
//                       <Link
//                         to={"/product"}
//                         className="cart-continue-shopping-link"
//                       >
//                         <MdKeyboardArrowLeft className="left-arrow-cart-cs" />
//                         <p className="mb-0">Continue shopping</p>
//                       </Link>
//                     </span>
//                   </div>
//                 </>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Cart;

// // import React, { useEffect, useState } from "react";
// // import { Link } from "react-router-dom";
// // import { FaIndianRupeeSign } from "react-icons/fa6";
// // import { MdKeyboardArrowLeft } from "react-icons/md";

// // const Cart = () => {
// //   const [cartItems, setCartItems] = useState([]);

// //   useEffect(() => {
// //     const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
// //     setCartItems(storedCartItems);
// //   }, []);

// //   const handleDelete = (index) => {
// //     const updatedCartItems = cartItems.filter((_, i) => i !== index);
// //     setCartItems(updatedCartItems);
// //     localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
// //   };

// //   return (
// //     <section className="cart-page">
// //       <div className="our-container">
// //         <div className="inner-container">
// //           <div className="cart-page-block py-5">
// //             <div className="cart-page-heading">
// //               <h1>Your Shopping Cart</h1>
// //             </div>
// //             <div className="cart-page-body">
// //               <ul className="list-group">
// //                 {cartItems.length > 0 ? (
// //                   cartItems.map((item, index) => (
// //                     <li key={index} className="list-group-item d-flex justify-content-between">
// //                       <div className="cart-detail-list-item d-flex w-100">
// //                         <div className="left-side-cart-img">
// //                           <Link to={`/product/${item.id}`} className="cart-detail-list-item-link">
// //                             <img src={item.imagePath} alt={item.title} />
// //                           </Link>
// //                         </div>
// //                         <div className="right-side-cart-content ms-3 w-100">
// //                           <div className="right-cart-heading">
// //                             <Link to={`/product/${item.id}`} className="cart-detail-list-item-link">
// //                               <div className="h3 fw-medium">{item.title}</div>
// //                             </Link>
// //                             <div className="h5 mb-0 fw-bold">
// //                               <FaIndianRupeeSign className="cart-rupees-sign" />
// //                               {item.price}
// //                             </div>
// //                           </div>
// //                           <div className="right-cart-footer">
// //                             <button onClick={() => handleDelete(index)} className="faTrash-btn">
// //                               Delete
// //                             </button>
// //                           </div>
// //                         </div>
// //                       </div>
// //                     </li>
// //                   ))
// //                 ) : (
// //                   <p>No items in the cart</p>
// //                 )}
// //               </ul>
// //               <div className="d-flex align-items-center justify-content-end">
// //                 <span>
// //                   <Link to={"/product"} className="cart-continue-shopping-link">
// //                     <MdKeyboardArrowLeft className="left-arrow-cart-cs" />
// //                     <p className="mb-0">Continue shopping</p>
// //                   </Link>
// //                 </span>
// //               </div>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </section>
// //   );
// // };

// // export default Cart;

// // // import React, { useEffect, useState } from "react";
// // // import { Link } from "react-router-dom";
// // // import { FaIndianRupeeSign } from "react-icons/fa6";
// // // import { MdKeyboardArrowLeft } from "react-icons/md";

// // // const Cart = () => {
// // //   const [cartItems, setCartItems] = useState([]);

// // //   useEffect(() => {
// // //     const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
// // //     setCartItems(storedCartItems);
// // //   }, []);

// // //   // Function to delete a product from the cart
// // //   const handleDelete = (index) => {
// // //     const updatedCartItems = cartItems.filter((_, i) => i !== index);
// // //     setCartItems(updatedCartItems);
// // //     localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
// // //   };

// // //   return (
// // //     <>
// // //       <section className="cart-page">
// // //         <div className="our-container">
// // //           <div className="inner-container">
// // //             <div className="cart-page-block py-5">
// // //               <div className="cart-page-heading">
// // //                 <h1>Your Shopping Cart</h1>
// // //               </div>
// // //               <div className="cart-page-body">
// // //                 <ul className="list-group">
// // //                   {cartItems.length > 0 ? (
// // //                     cartItems.map((item, index) => (
// // //                       <li
// // //                         key={index}
// // //                         className="list-group-item d-flex justify-content-between"
// // //                       >
// // //                         <div className="cart-detail-list-item d-flex w-100">
// // //                           <div className="left-side-cart-img">
// // //                             <Link
// // //                               to={`/product/${item.id}`}
// // //                               className="cart-detail-list-item-link"
// // //                             >
// // //                               <img src={item.imagePath} alt={item.title} />
// // //                             </Link>
// // //                           </div>
// // //                           <div className="right-side-cart-content ms-3 w-100">
// // //                             <div className="right-cart-heading">
// // //                               <Link
// // //                                 to={`/product/${item.id}`}
// // //                                 className="cart-detail-list-item-link"
// // //                               >
// // //                                 <div className="h3 fw-medium">{item.title}</div>
// // //                               </Link>
// // //                               <div className="h5 mb-0 fw-bold">
// // //                                 <FaIndianRupeeSign className="cart-rupees-sign" />
// // //                                 {item.price}
// // //                               </div>
// // //                             </div>

// // //                             <div className="right-cart-footer">
// // //                               <button
// // //                                 onClick={() => handleDelete(index)}
// // //                                 className="faTrash-btn"
// // //                               >
// // //                                 Delete
// // //                               </button>
// // //                             </div>
// // //                           </div>
// // //                         </div>
// // //                       </li>
// // //                     ))
// // //                   ) : (
// // //                     <p>No items in the cart</p>
// // //                   )}
// // //                 </ul>

// // //                 <div className="d-flex align-items-center justify-content-end">
// // //                   <span>
// // //                     <Link
// // //                       to={"/product"}
// // //                       className="cart-continue-shopping-link"
// // //                     >
// // //                       <MdKeyboardArrowLeft className="left-arrow-cart-cs" />
// // //                       <p className="mb-0">Continue shopping</p>
// // //                     </Link>
// // //                   </span>
// // //                 </div>
// // //               </div>
// // //             </div>
// // //           </div>
// // //         </div>
// // //       </section>
// // //     </>
// // //   );
// // // };

// // // export default Cart;

// // // // import React from "react";
// // // // import { Link } from "react-router-dom";
// // // // import { FaIndianRupeeSign } from "react-icons/fa6";
// // // // import { MdKeyboardArrowLeft } from "react-icons/md";

// // // // const Cart = () => {
// // // //   return (
// // // //    <>
// // // //         <section className="cart-page">
// // // //       <div className="our-container">
// // // //         <div className="inner-container">
// // // //           <div className="cart-page-block py-5">
// // // //             <div className="cart-page-heading">
// // // //               <h1>Your Shopping Cart</h1>
// // // //             </div>
// // // //             <div className="cart-page-body">
// // // //                   <ul className="list-group">
// // // //                     {/* {cartItems.map((item, index) => (
// // // //                       <li
// // // //                         key={index}
// // // //                         className="list-group-item d-flex justify-content-between"
// // // //                       >
// // // //                         <div className="cart-detail-list-item d-flex w-100">
// // // //                           <div className="left-side-cart-img">
// // // //                             <Link
// // // //                               to={`/product/${item.pkProdId}`}
// // // //                               className="cart-detail-list-item-link"
// // // //                             >
// // // //                               <img src={''} alt="" />
// // // //                             </Link>
// // // //                           </div>
// // // //                           <div className="right-side-cart-content ms-3 w-100">
// // // //                             <div className="right-cart-heading">
// // // //                               <Link
// // // //                                 to={`/product/${item.pkProdId}`}
// // // //                                 className="cart-detail-list-item-link"
// // // //                               >
// // // //                                 <div className="h3 fw-medium">
// // // //                                   {item.prodName}
// // // //                                 </div>
// // // //                               </Link>
// // // //                               <div className="h5 mb-0 fw-bold">
// // // //                                 <FaIndianRupeeSign className="cart-rupees-sign" />
// // // //                                 {item.prodPrice}
// // // //                               </div>
// // // //                             </div>

// // // //                             <div className="right-cart-footer">
// // // //                               <div
// // // //                                 className="cart-trash-btn ms-0"
// // // //                                 style={{ border: 0 }}
// // // //                               >
// // // //                                 <button
// // // //                                   className="faTrash-btn"
// // // //                                 >
// // // //                                   Delete
// // // //                                 </button>
// // // //                               </div>
// // // //                             </div>
// // // //                           </div>
// // // //                         </div>
// // // //                       </li>
// // // //                     ))} */}
// // // //                   </ul>
// // // //                   {/* <div className="d-flex mt-5 mb-3 cart-sub-total">
// // // //                     <div className="h4 mb-0 fw-bold">
// // // //                       <span className="fw-normal">
// // // //                         Subtotal ({cartItems.length} items) :{" "}
// // // //                       </span>
// // // //                       <FaIndianRupeeSign className="subtotal-rupees-sign mb-2" />
// // // //                       {getTotalPrice()}
// // // //                     </div>
// // // //                     <div className="cart-footer-btn-group">
// // // //                       {cartItems.length > 1 && (
// // // //                         <button
// // // //                           onClick={clearCart}
// // // //                           className="cart-sub-total-btn"
// // // //                         >
// // // //                           Clear Cart
// // // //                         </button>
// // // //                       )}
// // // //                       <Link
// // // //                         to={{
// // // //                           pathname: "/checkout",
// // // //                           state: { cartItems },
// // // //                         }}
// // // //                         className="cart-sub-total-btn"
// // // //                       >
// // // //                         Proceed to Buy
// // // //                       </Link>
// // // //                     </div>
// // // //                   </div> */}
// // // //                   <div className="d-flex align-items-center justify-content-end">
// // // //                     <span>
// // // //                       <Link
// // // //                         to={"/product"}
// // // //                         className="cart-continue-shopping-link"
// // // //                       >
// // // //                         <MdKeyboardArrowLeft className="left-arrow-cart-cs" />
// // // //                         <p className="mb-0">Continue shopping</p>
// // // //                       </Link>
// // // //                     </span>
// // // //                   </div>
// // // //             </div>
// // // //           </div>
// // // //         </div>
// // // //       </div>
// // // //     </section>
// // // //    </>
// // // //   )
// // // // }

// // // // export default Cart
