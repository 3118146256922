import React, { createContext, useContext, useState } from "react";

// Create a Context for the Cart
const CartContext = createContext();

// Create a provider component
export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems")) || []
  );

  // const addToCart = (item) => {
  //   setCartItems((prevItems) => {
  //     const existingItem = prevItems.find(
  //       (cartItem) => cartItem.id === item.id
  //     );
  //     let updatedItems;

  //     if (existingItem) {
  //       updatedItems = prevItems.map((cartItem) =>
  //         cartItem.id === item.id && cartItem.size === item.size
  //           ? { ...cartItem, quantity: cartItem.quantity + 1 }
  //           : cartItem
  //       );
  //     } else {
  //       updatedItems = [...prevItems, { ...item, quantity: 1 }];
  //     }

  //     localStorage.setItem("cartItems", JSON.stringify(updatedItems)); // Sync with localStorage
  //     return updatedItems; // Return updated state
  //   });
  // };
  const addToCart = (item) => {
    setCartItems((prevItems) => {
      // Find if the same product with the same size exists in the cart
      const existingItem = prevItems.find(
        (cartItem) => cartItem.id === item.id && cartItem.size === item.size
      );
      let updatedItems;
  
      if (existingItem) {
        // If it exists, update the quantity
        updatedItems = prevItems.map((cartItem) =>
          cartItem.id === item.id && cartItem.size === item.size
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        );
      } else {
        // If it doesn't exist, add a new item
        updatedItems = [...prevItems, { ...item, quantity: 1 }];
      }
  
      localStorage.setItem("cartItems", JSON.stringify(updatedItems)); // Sync with localStorage
      return updatedItems; // Return updated state
    });
  };

  const removeFromCart = (index) => {
    setCartItems((prevItems) => {
      const updatedCart = prevItems.filter((_, i) => i !== index);
      localStorage.setItem("cartItems", JSON.stringify(updatedCart)); // Sync with localStorage
      return updatedCart; // Return updated state
    });
  };

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart }}>
      {children}
    </CartContext.Provider>
  );
};

// Create a custom hook to use the Cart Context
export const useCart = () => {
  return useContext(CartContext);
};





// import React, { createContext, useContext, useState } from "react";

// // Create a Context for the Cart
// const CartContext = createContext();

// // Create a provider component
// export const CartProvider = ({ children }) => {
//   const [cartItems, setCartItems] = useState(
//     JSON.parse(localStorage.getItem("cartItems")) || []
//   );

//   //   const addToCart = (item) => {
//   //     setCartItems((prevItems) => {
//   //       const existingItem = prevItems.find(
//   //         (cartItem) => cartItem.id === item.id
//   //       );
//   //       if (existingItem) {
//   //         return prevItems.map((cartItem) =>
//   //           cartItem.id === item.id
//   //             ? { ...cartItem, quantity: cartItem.quantity + 1 }
//   //             : cartItem
//   //         );
//   //       }
//   //       return [...prevItems, item];
//   //     });
//   //     localStorage.setItem("cartItems", JSON.stringify(cartItems));
//   //   };

//   //   const removeFromCart = (index) => {
//   //     const updatedCart = cartItems.filter((_, i) => i !== index);
//   //     setCartItems(updatedCart);
//   //     localStorage.setItem("cartItems", JSON.stringify(updatedCart)); // Sync with localStorage
//   // };
//   const addToCart = (item) => {
//     setCartItems((prevItems) => {
//       const existingItem = prevItems.find(
//         (cartItem) => cartItem.id === item.id
//       );
//       let updatedItems;

//       if (existingItem) {
//         updatedItems = prevItems.map((cartItem) =>
//           cartItem.id === item.id
//             ? { ...cartItem, quantity: cartItem.quantity + 1 }
//             : cartItem
//         );
//       } else {
//         updatedItems = [...prevItems, { ...item, quantity: 1 }];
//       }

//       localStorage.setItem("cartItems", JSON.stringify(updatedItems)); // Sync with localStorage
//       return updatedItems; // Return updated state
//     });
//   };

//   const removeFromCart = (index) => {
//     setCartItems((prevItems) => {
//       const updatedCart = prevItems.filter((_, i) => i !== index);
//       localStorage.setItem("cartItems", JSON.stringify(updatedCart)); // Sync with localStorage
//       return updatedCart; // Return updated state
//     });
//   };

//   return (
//     <CartContext.Provider value={{ cartItems, addToCart, removeFromCart }}>
//       {children}
//     </CartContext.Provider>
//   );
// };

// // Create a custom hook to use the Cart Context
// export const useCart = () => {
//   return useContext(CartContext);
// };