import React from "react";
import MetaDescTag from "../../components/MetaDescTag";

const PrivacyPolicy = () => {
  return (
    <>
      <MetaDescTag
        title={`Privacy Policy | EthnicINDI`}
        description={`ethnicindi.com Respects Your Privacy. This Privacy Policy Outlines the Manner Your Data Is Collected and Used by Us.`}
      />
      <section className="py-5 privacy-policy-page">
        <div className="our-container">
          <div className="inner-container mx-auto">
            <div className="mb-4 privacy-policy-page-heading">
              <h1 className="fs-2 fw-medium text-center text-uppercase">
                PRIVACY POLICY
              </h1>
            </div>
            <div className="privacy-policy-page-body">
              <div className="mb-4 privacy-policy-greeting">
                <h2 className="fs-5 fw-medium text-center text-uppercase">
                  "GREETING FROM ETHNICINDI"
                </h2>
                <div className="privacy-policy-greeting-body">
                  <p className="fs-6 text-capitalize mb-2">
                    <span className="text-lowercase">ethnicindi.com</span>{" "}
                    Respects Your Privacy. This Privacy Policy Outlines the
                    Manner Your Data Is Collected and Used by Us.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    You Are Advised to Please Read the Privacy Policy Carefully.
                    By Accessing The Services Provided by{" "}
                    <span className="text-lowercase">ethnicindi.com</span> You
                    Agree to The Collection and Use of Your Data{" "}
                    <span className="text-lowercase">ethnicindi.com</span> in
                    The Manner Provided in This Privacy Policy.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    If You Have Questions or Concerns Regarding This Statement,
                    You Can Contact Us At 7304191806 (10.00 Am To 7.00 Pm, Mon
                    to Sat) Or Email Us at{" "}
                    <span className="text-lowercase">ethnicindi@gmail.com</span>
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    The Policy Does Not Apply to The Procedures and Practices
                    Followed by Entities That Are Not Managed, Owned or
                    Controlled by{" "}
                    <span className="text-lowercase">ethnicindi.com</span>
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    Through This Document, We Wish to Make You Feel at Ease
                    While Using Our Websites, And Not Hesitate While Sharing
                    Important Information with Us. We Like to Make You Aware
                    That by Using Our Websites You Are Agreeing to The
                    Collection of Certain Data.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    Do Feel Contact Us with Your Questions or Concerns About
                    Privacy on This Website.
                  </p>
                </div>
              </div>
              <div className="mb-4 privacy-policy-information">
                <div className="h4 fs-5 fw-medium text-left text-uppercase">
                  INFORMATION THAT MAY BE COLLECTED FROM YOU
                </div>
                <div className="mb-3 privacy-policy-information-body">
                  <p className="fs-6 text-capitalize mb-2">
                    <span className="text-lowercase">ethnicindi.com</span>{" "}
                    Collects the Details Provided by You on Registration
                    Together with Information We Learn About You from Your Use
                    of Our Service and Your Visits to Our Website and Other
                    Websites Accessible from Them.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    We May Collect Additional Information in Connection with
                    Your Participation in Any Promotions or Contests Offered by
                    Us and Information You Provide When Giving Us Feedback or
                    Completing Profile Forms. We Also Monitor Customer Traffic
                    Patterns and Website Use, Which Enables Us to Improve the
                    Service We Provide. We Will Collect Only Such Information as
                    Is Necessary and Relevant to Us to Provide You with The
                    Services Available on The Website.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    While You Use Our Website, We May Have Automatic Access To
                    (Receive and Collect) Certain Anonymous Information in
                    Standard Usage Logs Through Our Web Server, Obtained from
                    Cookies Sent to Your Browser from Web Server Cookies Stored
                    on Your Hard Drive, Including but Not Limited To:
                  </p>
                  <ul>
                    <li>Computer-Identification Information.</li>
                    <li>IP Address, Assigned to The Computer Which You Use.</li>
                    <li>
                      The Domain Server Through Which You Access Our Service.
                    </li>
                    <li>The Type of Computer You Are Using.</li>
                    <li>The Type of Web Browser You Are Using.</li>
                  </ul>
                  <p className="fs-6 text-capitalize mb-2">
                    While You Are Transacting on Our Website Personally
                    Identifiable Information Collected About You May Include:
                  </p>
                  <ul>
                    <li>First And Last Name.</li>
                    <li>Email Address.</li>
                    <li>Contact Details Including Phone Numbers.</li>
                    <li>
                      Pin/Zip Code. - Demographic Profile (Like Your Age,
                      Gender, Occupation, Education, Address and Durables
                      Owned).
                    </li>
                    <li>
                      Personal Preferences and Interests (Such as Books, Movies,
                      Music and So On); And Your Opinion on Services, Products,
                      Features on Our Websites.
                    </li>
                  </ul>
                  <p className="fs-6 text-capitalize mb-2">
                    We May Also Collect the Following Information About:
                  </p>
                  <ul>
                    <li>The Pages You Visit/Access.</li>
                    <li>The Links You Click on Our Website.</li>
                    <li>The Number of Times You Access the Page.</li>
                    <li>Things You View, Add to Bag, Add to Wish List.</li>
                    <li>
                      Other Websites You Open Simultaneously While Browsing on
                      Ours.
                    </li>
                  </ul>
                  <p className="fs-6 text-capitalize mb-2">
                    You May Terminate Your Account at Any Time by Writing to Us
                    at <span className="text-lowercase">ethnicindi.com</span>{" "}
                    from The Email Id You Have Used for Creating Your Account
                    Login, And Mentioning Please Close My{" "}
                    <span className="text-lowercase">ethnicindi.com</span>{" "}
                    Account in The Subject Line. We Will Disable Access to Your
                    Account Within 2 Working Days of Receiving Your Mail.
                    However, Your Information May Remain Stored in Archive on
                    Our Servers Even After the Deletion or Termination of Your
                    Account.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    Kindly, Note That{" "}
                    <span className="text-lowercase">ethnicindi.com</span> Does
                    Not Collect Your Payment Card Details (I.E. Credit/Debit
                    Card Number, Expiration Date, Cvv Etc.). When You Make A
                    Purchase Using Your Card, You Are Automatically Redirected
                    to The Acquiring Bank Payment Gateway Website, Where All
                    Required Transaction Details Are Captured on The Secured
                    Payment Page.
                  </p>
                </div>
              </div>
              <div className="mb-4 privacy-policy-security">
                <div className="h4 fs-5 fw-medium text-left text-uppercase">
                  SECURITY
                </div>
                <div className="mb-3 privacy-policy-security-body">
                  <p className="fs-6 text-capitalize mb-2">
                    We Are Dedicated to Protecting the Security of Your
                    Information. We Have Put in Place Appropriate Physical,
                    Technological, And Administrative Policies to Safeguard and
                    Secure the Information We Collect Online in Order to Prevent
                    Unauthorised Access or Disclosure.
                  </p>
                </div>
              </div>
              <div className="mb-4 privacy-policy-cookies">
                <div className="h4 fs-5 fw-medium text-left text-uppercase">
                  COOKIES
                </div>
                <div className="mb-3 privacy-policy-cookies-body">
                  <p className="fs-6 text-capitalize mb-2">
                    A Cookie Is A Little File That Requests Authorization to Be
                    Stored on The Hard Disk of Your Computer. Once You Accept,
                    The File Is Added, And the Cookie Notifies You When You
                    Visit A Specific Website or Assists in Analysing Online
                    Traffic. Cookies Enable Personalized Responses from Web
                    Apps. By Collecting And Storing Information About Your
                    Preferences, The Web Application Can Adjust Its
                    Functionality to Your Needs, Preferences, And Likes and
                    Dislikes.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    Cookies from Traffic Logs Are Utilized by Us to Determine
                    Which Pages Are Being Used. This Aids in The Analysis of
                    Website Traffic Statistics and Helps Us Make Improvements to
                    Our Website to Better Meet the Needs of Users. This Data Is
                    Only Used for Statistical Analysis; After That, It Is
                    Deleted from The System.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    In General, Cookies Enable Us to Track Which Pages You Find
                    Useful and Why You Do Not, Which Helps Us Provide You with A
                    Better Experience On Our Website. Other Than the Information
                    You Choose to Share with Us, A Cookie In No Way Allows Us
                    Access To Your Computer Or Any Personal Information About
                    You.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    You Have The Option To Accept Or Reject Cookies. Although
                    The Majority Of Web Browsers Accept Cookies By Default, You
                    Can Typically Change Your Browser's Settings To Reject
                    Cookies If You'd Like. You Might Not Be Able To Use The
                    Website To Its Full Potential As A Result.
                  </p>
                </div>
              </div>
              <div className="mb-4 privacy-policy-disclaimer">
                <div className="h4 fs-5 fw-medium text-left text-uppercase">
                  DISCLAIMER
                </div>
                <div className="mb-3 privacy-policy-disclaimer-body">
                  <p className="fs-6 text-capitalize mb-2">
                    We Reserve the Right To Change The Terms And Privacy Policy
                    From Time To Time As We Deem Fit, Without Any Prior
                    Intimation To You. Your Continued Use of The Web Site
                    Signifies Your Acceptance Of Any Amendment To These Terms.
                    You Are Therefore Advised To Read The Privacy Policy On A
                    Regular Basis. In Case You Disagree with Any Of The Terms
                    And Privacy Policies Or Any Amendments Thereafter, You May
                    Terminate Your Use Of This Website Immediately.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    We Follow Generally Accepted Standards To Protect The
                    Personal Information Submitted To Us, Including The Use Of
                    Services Form Third Party Service Providers. Therefore,
                    While We Strive To Use Commercially Acceptable Means To
                    Protect Your Personal Information, We Cannot Guarantee
                    Absolute Security And Thereby Usage In A Manner That Is
                    Inconsistent With This Privacy Policy.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    We Assume No Liability Or Responsibility For Disclosure Of
                    Your Information Due To Errors In Transmission, Unauthorized
                    Third-Party Access, Or Other Causes Beyond Our Control. The
                    On us Of Protecting The Classified/Personal Information
                    Details Of Every User Solely Depends On Them And We Shall
                    Take No Liability In Case Of Any Breach Thereupon. We
                    Recommend You To Protect Your Personal Information By Never
                    Providing You Credit Card, Debit Or Any Other Bank Account
                    Details To Anyone.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    Ethnicindi Provides Maximum Care as Possible to Ensure That
                    All or Any Data/Information in Respect of Electronic
                    Transfer of Money Remain Secure. For Completing Online
                    Transactions Involving Payments A User Is Directed to A
                    Secured Payment Gateway, Ethnicindi Pvt Ltd.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    Do Not Store or Keep Financial Data Such as Credit Card
                    Numbers/Passwords/Pins Etc Or Any “Personal Account Related
                    Information” For Its Own Use. Since the Transaction Happens
                    on A Third-Party Network Not Controlled by Ethnicindi, Once
                    an Online Transaction Has Been Completed, The Personal
                    Account Related Information Is Not Accessible to Anyone at
                    Ethnicindi, At the Payment Gateway, And This Ensures Maximum
                    Security.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    Ethnicindi Shall Not Be Liable for Any Loss or Damage
                    Sustained by Reason of Any Disclosure (Inadvertent or
                    Otherwise) Of Any Information Concerning the User’s Account
                    And/Or Information Relating to Or Regarding Online
                    Transactions Using Credit Cards/Debit Cards And/Or Their
                    Verification Process and Particulars not For Any Error,
                    Omission Or Inaccuracy With Respect To Any Information So
                    Disclosed And Used Whether Or Not In Pursuance Of A Legal
                    Process Or Otherwise. You Play an Important Role in Keeping
                    Your Personal Information Secure. You Should Not Share Your
                    User Name, Password, Or Other Security Information of Your
                    Ethnicindi Account with Anyone. If We Receive Instructions
                    Using Your User Name and Password, We Will Consider That You
                    Have Authorized the Instructions.
                  </p>
                </div>
              </div>
              <div className="mb-4 privacy-policy-contact-info">
                <div className="h4 fs-5 fw-medium text-left text-uppercase">
                  CONTACT INFORMATION
                </div>
                <div className="mb-3 privacy-policy-contact-info-body">
                  <p className="fs-6 text-capitalize mb-2">
                    You Can Contact Us At:
                  </p>
                  <p className="fs-6 text-capitalize mb-2">Tel. : 7304191806</p>
                  <p className="fs-6 text-capitalize mb-2">
                    E-mail ID :{" "}
                    <span className="text-lowercase">ethnicindi@gmail.com</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
