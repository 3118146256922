export const productData = {
  status: "Successful",
  code: 200,
  data: [
    {
      id: 1,
      title: "Jumpsuit with Floral Jacket",
      material: "Sequin & Thread work on the jacket",
      price: 8599,
      discountPrice: 6499,
      sellingPrice: 13500,
      color: "#e094a6",
      fabric: "Georgette with detailed printing",
      size: "L",
      design: "",
      sku: "ETH003J",
      description:
        "Fusion of comfort & fashion making it an ideal choice  for any special occasion",
      image: "../images/models/img-1-01.jpg",
      imagePath: [
        "../images/models/img-1-01.jpg",
        "../images/models/img-1-02.jpg",
        "../images/models/img-1-03.jpg",
      ],
    },
    {
      id: 2,
      title: "Yellow Party Wear Crop Top & Dhoti Set",
      material:
        "Position print full sleeves long jacket with satin dhoti & crop top",
      price: 9999,
      discountPrice: 7500,
      sellingPrice: 12500,
      color: "#f19c42",
      fabric: "Georgette & Satin Dhoti 3 Pcs set",
      size: "L",
      design: "Stone, embroidery, sequins & mirror work",
      sku: "ETH0013D",
      description:
        "A crop top & dhoti set with jacket suitable for various wedding functions & festivals",
      image: "../images/models/img-2-01.jpg",
      imagePath: [
        "../images/models/img-2-01.jpg",
        "../images/models/img-2-02.jpg",
        "../images/models/img-2-03.jpg",
        "../images/models/img-2-04.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-2-04.png",
    },
    {
      id: 3,
      title: "Yellow Embroidered & Sequins Work Jumpsuit",
      material: "Abala Material",
      price: 9000,
      discountPrice: 7500,
      sellingPrice: 19800,
      color: "#fecb5c",
      fabric: "Georgette Sider Jumpsuit",
      size: "L",
      design: "Embroidery, sequins & Mirror work",
      sku: "ETH0017J",
      description:
        "Embellished with premium fabric, embroidery, sequins & mirror work",
      image: "../images/models/img-3-01.jpg",
      imagePath: [
        "../images/models/img-3-01.jpg",
        "../images/models/img-3-02.jpg",
        "../images/models/img-3-03.jpg",
        "../images/models/img-3-04.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-3-04.png",
    },
    {
      id: 4,
      title: "Floor Length Gown with Heavy Thread Work",
      material: "",
      price: 13999,
      discountPrice: 11999,
      sellingPrice: 14500,
      color: "#454663",
      fabric: "Georgette Heavy thread work gown",
      size: "L",
      design: "Computer Embroidery work",
      sku: "ETH0014G",
      description: "Georgette floor length gown for special occassions",
      image: "../images/models/img-4-01.jpg",
      imagePath: [
        "../images/models/img-4-01.jpg",
        "../images/models/img-4-02.jpg",
        "../images/models/img-4-03.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-4-03.png",
    },
    {
      id: 5,
      title: "white woven gharara set",
      material: "Woven Thread work on top & Gharara",
      price: 8999,
      discountPrice: 5500,
      sellingPrice: 11500,
      color: "#ffffff",
      fabric: "Chinnon",
      size: "L",
      Design: "",
      sku: "ETH0022D",
      description:
        "A chic and modern ensemble, a white woven crop top gharara set",
      image: "../images/models/img-5-01.jpg",
      imagePath: [
        "../images/models/img-5-01.jpg",
        "../images/models/img-5-02.jpg",
      ],
    },
    {
      id: 6,
      title: "Jumpsuit with Floral Jacket",
      material: "",
      price: 12000,
      discountPrice: 8500,
      sellingPrice: 19800,
      color: "#034284",
      fabric: "Crepe with floral jacket jumpsuit",
      size: "L",
      design: "Mirror & Stone work",
      sku: "ETH0012J",
      description:
        "Modern jumpsuit for your cocktail reception, Engagement party & many more",
      image: "../images/models/img-6-01.jpg",
      imagePath: [
        "../images/models/img-6-01.jpg",
        "../images/models/img-6-02.jpg",
        "../images/models/img-6-03.jpg",
        "../images/models/img-6-04.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-6-04.png",
    },
    {
      id: 7,
      title: "Multi-coloured Lace Work Kurti and Pant",
      material:
        "Multi colored lace work around kurti & pant bodies . Diamond work on kurti",
      price: 8000,
      discountPrice: 6500,
      sellingPrice: 14500,
      color: "#e85a7e",
      fabric: "Fabric - A-Line Silk Crepe Straight Kurti Set",
      size: "L",
      design: "",
      sku: "ETH0018D",
      description:
        "A - Line kurti crafted from luxurious silk crepe, offering both sophistication & comfort",
      image: "../images/models/img-7-01.jpg",
      imagePath: [
        "../images/models/img-7-01.jpg",
        "../images/models/img-7-02.jpg",
        "../images/models/img-7-03.jpg",
      ],
    },
    {
      id: 8,
      title: "Fully Embroidered  Cord set",
      material: "Thread work & mirror work",
      price: 9999,
      discountPrice: 6500,
      sellingPrice: 12500,
      color: "#63b5d2",
      fabric: "Georgette",
      size: "L",
      design: "",
      sku: "ETH0024D",
      description:
        "An elegant and timeless ensemble, an ethnic thread work co-ord set is perfect for various occasions",
      image: "../images/models/img-8-01.jpg",
      imagePath: [
        "../images/models/img-8-01.jpg",
        "../images/models/img-8-02.jpg",
        "../images/models/img-8-03.jpg",
      ],
    },
    {
      id: 9,
      title: "Palazo Set with Heavy Work Stylish Blouse",
      material: "Thread work & mirror work",
      price: 8999,
      discountPrice: 5500,
      sellingPrice: 11500,
      color: "#dad3c6",
      fabric: "Georgette",
      size: "L",
      design: "",
      sku: "ETH0023D",
      description:
        "A bold and glamorous ensemble, this V-neck crossfit fully diamond work blouse and palazzo set",
      image: "../images/models/img-9-01.jpg",
      imagePath: [
        "../images/models/img-9-01.jpg",
        "../images/models/img-9-02.jpg",
        "../images/models/img-9-03.jpg",
        "../images/models/img-9-04.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-9-04.png",
    },
    {
      id: 10,
      title: "Indo-Western Palazo with designer blouse & Shrug",
      material: "wide leg palazzo with work on jacket sleeves",
      price: 7999,
      discountPrice: 6999,
      sellingPrice: 12500,
      color: "#e58150",
      fabric: "Georgette",
      size: "L",
      design: "",
      sku: "ETH0021D",
      description:
        "A vibrant and elegant ensemble, this yellow embroidered palazzo set is perfect for various occasions",
      image: "../images/models/img-10-01.jpg",
      imagePath: [
        "../images/models/img-10-01.jpg",
        "../images/models/img-10-02.jpg",
      ],
    },
    {
      id: 11,
      title: "Multicolor Embroidered Silk Mehendi Lehenga Choli",
      material: "",
      price: 15999,
      discountPrice: 12699,
      sellingPrice: 23500,
      color: "#b4aa9a",
      fabric: "Silk Mehendi Lehenga Choli",
      size: "L",
      design: "computer print heavy embroidery",
      sku: "ETH0016L",
      description:
        "Elegance redefined with our multi colourheavy embroidered brocade georgette lehenga set",
      image: "../images/models/img-11-01.jpg",
      imagePath: [
        "../images/models/img-11-01.jpg",
        "../images/models/img-11-02.jpg",
        "../images/models/img-11-03.jpg",
      ],
    },
    {
      id: 12,
      title: "Ghaghara Choli",
      material: "",
      price: 11099,
      discountPrice: 8999,
      sellingPrice: 14500,
      color: "#fe5c1b",
      fabric: "Georgette with Spaghetti  blouse",
      size: "L",
      design: "position print lehenga set",
      sku: "ETH007L",
      description:
        "Elevate your ethnic wardrobe with our orange bandhani embroidered lehenga set",
      image: "../images/models/img-12-01.jpg",
      imagePath: [
        "../images/models/img-12-01.jpg",
        "../images/models/img-12-02.jpg",
        "../images/models/img-12-03.jpg",
      ],
    },
    {
      id: 13,
      title: "Ghaghara Choli",
      material: "",
      price: 7999,
      discountPrice: 4999,
      sellingPrice: 18500,
      color: "#459187",
      fabric: "Georgette with V neck blouse",
      size: "L",
      design: "position print & mirror",
      sku: "ETH009L",
      description:
        "Crafted from premium quality georgette fabric, this lehenga set promises comfort & sophistication",
      image: "../images/models/img-13-01.jpg",
      imagePath: [
        "../images/models/img-13-01.jpg",
        "../images/models/img-13-02.jpg",
        "../images/models/img-13-03.jpg",
      ],
    },
    {
      id: 14,
      title: "Floor Length Gown with Heavy Border & Belt",
      material: "georgette print gown with embroidered belt",
      price: 9999,
      discountPrice: 8500,
      sellingPrice: 13500,
      color: "#f68c7b",
      fabric: "Habotai Silk  Floor length  Gown",
      size: "L",
      design: "sequin & mirror work with heavy embroidered border",
      sku: "ETH0015G",
      description:
        "It strike a perfect balance between modesty & modernity, enhancing the overall appeal of the gown",
      image: "../images/models/img-14-01.jpg",
      imagePath: [
        "../images/models/img-14-01.jpg",
        "../images/models/img-14-02.jpg",
        "../images/models/img-14-03.jpg",
      ],
    },
    {
      id: 15,
      title:
        "Ghaghara Choli",
      material: "",
      price: 13999,
      discountPrice: 11999,
      sellingPrice: 18500,
      color: "#333f1a",
      fabric: "Georgette with Hand work blouse",
      size: "L",
      design: "",
      sku: "ETH004L",
      description:
        "The lehenga in a vibrant shade of green, exudes vibrancy & charm",
      image: "../images/models/img-15-01.jpg",
      imagePath: [
        "../images/models/img-15-01.jpg",
        "../images/models/img-15-02.jpg",
        "../images/models/img-15-03.jpg",
      ],
    },
    {
      id: 16,
      title: "Ghaghara Choli",
      material:
        "fully handmade choli/blouse & lightweight lehenga with sequence & mirror work",
      price: 12999,
      discountPrice: 8999,
      sellingPrice: 19800,
      color: "#75a1a8",
      fabric: "Georgette with Hand work blouse",
      size: "L",
      design: "",
      sku: "ETH0019L",
      description:
        "This lehenga appropriate for any event & wonderful blend of classic elegance & modern flair",
      image: "../images/models/img-16-01.jpg",
      imagePath: [
        "../images/models/img-16-01.jpg",
        "../images/models/img-16-02.jpg",
        "../images/models/img-16-03.jpg",
      ],
    },
    {
      id: 17,
      title: "Ghaghara Choli",
      material: "jacquard print lehenga & handmade choli",
      price: 18999,
      discountPrice: 14499,
      sellingPrice: 22900,
      color: "#a50133",
      fabric: "Georgette Weaving Jacquard print",
      size: "L",
      design: "",
      sku: "ETH008L",
      description:
        "Experience the epitome of luxury & grace in our weaving jacquard lehenga choli & handmade blouse",
      image: "../images/models/img-17-01.jpg",
      imagePath: [
        "../images/models/img-17-01.jpg",
        "../images/models/img-17-02.jpg",
        "../images/models/img-17-03.jpg",
      ],
    },
    {
      id: 18,
      title: "Ghaghara Choli",
      material: "net tikali & handmade diamond blouse",
      price: 12999,
      discountPrice: 9999,
      sellingPrice: 18500,
      color: "#1e5c44",
      fabric: "Georgette with Hand work blouse",
      size: "L",
      design: "",
      sku: "ETH006L",
      description:
        "Envelop yourself in ethereal beauty with our ethnicINDI creation in this wedding season such as engagement, festivals, & ceremonies",
      image: "../images/models/img-18-01.jpg",
      imagePath: [
        "../images/models/img-18-01.jpg",
        "../images/models/img-18-02.jpg",
        "../images/models/img-18-03.jpg",
      ],
    },
    {
      id: 19,
      title:
        "Ghaghara Choli",
      material: "georgette handwork lehenga choli",
      price: 9099,
      discountPrice: 7700,
      sellingPrice: 19800,
      color: "#870658",
      fabric: "Georgette with Tikli work",
      size: "L",
      design: "sequin mirror work & tikali work",
      sku: "ETH001L",
      description: "Perfect blend of class & sass with our ghagra choli",
      image: "../images/models/img-19-01.jpg",
      imagePath: [
        "../images/models/img-19-01.jpg",
        "../images/models/img-19-02.jpg",
      ],
    },
    {
      id: 20,
      title: "Indo-Western full-length gown with long shrug &detachable belt",
      material: "georgette floor length gown & long shrug with detachable belt",
      price: 12000,
      discountPrice: 8500,
      sellingPrice: 19800,
      color: "#200b19",
      fabric: "Georgette Floor length Gown",
      size: "L",
      design: "stone, embroidery & mirror work",
      sku: "ETH0011G",
      description:
        "The fusion of full length gown, a graceful long shrug & detachable belt",
      image: "../images/models/img-20-01.jpg",
      imagePath: [
        "../images/models/img-20-01.jpg",
        "../images/models/img-20-02.jpg",
        "../images/models/img-20-03.jpg",
        "../images/models/img-20-04.jpg",
        "../images/models/img-20-05.mp4",
      ],
      imageThumbnail: "../images/models/video-thumbnail-20-05.png",
    },
    {
      id: 21,
      title: "Floor Length Gown",
      material: "",
      price: 7999,
      discountPrice: 4999,
      sellingPrice: 19800,
      color: "#303538",
      fabric: "Georgette with detailed printing",
      size: "L",
      design: "",
      sku: "ETH002G",
      description:
        "A long flared gown with full sleeves & plain dupatta for your cocktail parties & festive wear",
      image: "../images/models/img-21-01.jpg",
      imagePath: ["../images/models/img-21-01.jpg"],
    },
    {
      id: 22,
      title: "Printed silk Ghaghara Choli and Jacket",
      material:
        "Printed silk lehenga & jacket with stone works & matching plain top",
      price: 7899,
      discountPrice: 6999,
      sellingPrice: 17550,
      color: "#f8ccad",
      fabric: "Pure Silk",
      size: "L",
      design: "",
      sku: "ETH005L",
      description: "",
      image: "../images/models/img-22-01.jpg",
      imagePath: ["../images/models/img-22-01.jpg"],
    },
    {
      id: 23,
      title: "Pink Designer Peplum Top with Bottom",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 16000,
      color: "#d11530",
      fabric: "Georgette",
      size: "XL",
      design: "",
      sku: "ETH0025D",
      description:
        "Pink Embroidered peplum top with net dupatta & flared lehenga style palazzo set",
      image: "../images/models/img-23-01.jpg",
      imagePath: ["../images/models/img-23-01.jpg"],
    },
    {
      id: 24,
      title: "Straight Pant Cotton Co-ords Set",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 2250,
      color: "#30333A",
      fabric: "Pure cotton",
      size: "S, M",
      design: "",
      sku: "CAS001D",
      description: "",
      image: "../images/models/img-24-01.jpg",
      imagePath: ["../images/models/img-24-01.jpg"],
    },
    {
      id: 25,
      title: "Neon Cotton Blend Kurta Set",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 1999,
      color: "#E5CF7B",
      fabric: "Cotton",
      size: "S, M, L",
      design: "",
      sku: "CAS002D",
      description: "",
      image: "../images/models/img-25-01.jpg",
      imagePath: ["../images/models/img-25-01.jpg"],
    },
    {
      id: 26,
      title: "Elegant White Embroidered Salwar Suit",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 2500,
      color: "#DBDBDD",
      fabric: "Cotton",
      size: "M",
      design: "",
      sku: "CAS003D",
      description: "",
      image: "../images/models/img-26-01.jpg",
      imagePath: ["../images/models/img-26-01.jpg"],
    },
    {
      id: 27,
      title: "Printed Shoulder Straps Kurta &Trousers",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 1800,
      color: "#283938",
      fabric: "Cotton",
      size: "S, M, L",
      design: "",
      sku: "CAS004D",
      description: "",
      image: "../images/models/img-27-01.jpg",
      imagePath: ["../images/models/img-27-01.jpg"],
    },
    {
      id: 28,
      title: "Peach Jaipuri Anarkali Kurti set",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 2500,
      color: "#EDA0AC",
      fabric: "Fabric - Pure Cotton",
      size: "S, M, L",
      design: "",
      sku: "CAS005D",
      description: "",
      image: "../images/models/img-28-01.jpg",
      imagePath: ["../images/models/img-28-01.jpg"],
    },
    {
      id: 29,
      title: "Pink Bandhni Print Kurta Sets",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 3200,
      color: "#CF3937",
      fabric: "Fabric - Georgette",
      size: "S, M",
      design: "",
      sku: "CAS006D",
      description: "",
      image: "../images/models/img-29-01.jpg",
      imagePath: ["../images/models/img-29-01.jpg"],
    },
    {
      id: 30,
      title: "Printed Cotton Blend White&Blue Flared Kurta Dupatta Set",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 2100,
      color: "#283956",
      fabric: "Fabric - Cotton",
      size: "M",
      design: "",
      sku: "CAS007D",
      description: "",
      image: "../images/models/img-30-01.jpg",
      imagePath: ["../images/models/img-30-01.jpg"],
    },
    {
      id: 31,
      title: "Straight Kurta with Embroidered Yoke & Shimmer Legging",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 1800,
      color: "#191A1B",
      fabric: "Fabric - Cotton",
      size: "M",
      design: "",
      sku: "CAS008D",
      description: "",
      image: "../images/models/img-31-01.jpg",
      imagePath: ["../images/models/img-31-01.jpg"],
    },
    {
      id: 32,
      title: "Paisley Printed Regular Kurta With Straight Pant",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 2200,
      color: "#5EB1BD",
      fabric: "Fabric - Cotton",
      size: "M, L, XL",
      design: "",
      sku: "CAS009D",
      description: "",
      image: "../images/models/img-32-01.jpg",
      imagePath: ["../images/models/img-32-01.jpg"],
    },
    {
      id: 33,
      title: "Digital Printed Silk Straight Kurta Set with Dupatta",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 4500,
      color: "#E3DBCF",
      fabric: "Fabric- Silk",
      size: "S, L",
      design: "",
      sku: "CAS0010D",
      description: "",
      image: "../images/models/img-33-01.jpg",
      imagePath: ["../images/models/img-33-01.jpg"],
    },
    {
      id: 34,
      title: "Cotton Silk Kurta Pant With Dupatta Set",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 1800,
      color: "#2E4D75",
      fabric: "Fabric - Cotton Silk",
      size: "S",
      design: "",
      sku: "CAS0011D",
      description: "",
      image: "../images/models/img-34-01.jpg",
      imagePath: ["../images/models/img-34-01.jpg"],
    },
    {
      id: 35,
      title: "Mehendi Anarkali Sets",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 2160,
      color: "#3D4A32",
      fabric: "Fabric - Chinnon",
      size: "M",
      design: "",
      sku: "CAS0012D",
      description: "",
      image: "../images/models/img-35-01.jpg",
      imagePath: ["../images/models/img-35-01.jpg"],
    },
    {
      id: 36,
      title: "Cotton Blend Kurta Palazzo Dupatta Set",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 1999,
      color: "#F4CB9B",
      fabric: "Fabric -Pure Cotton",
      size: "S, M, L",
      design: "",
      sku: "CAS0013D",
      description: "",
      image: "../images/models/img-36-01.jpg",
      imagePath: ["../images/models/img-36-01.jpg"],
    },
    {
      id: 37,
      title: "Straight Sequinned Kurta With Trousers & Dupatta",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 1800,
      color: "#EDCEC1",
      fabric: "Fabric - Cotton",
      size: "S, M, L",
      design: "",
      sku: "CAS0014D",
      description: "",
      image: "../images/models/img-37-01.jpg",
      imagePath: ["../images/models/img-37-01.jpg"],
    },
    {
      id: 38,
      title: "Aurelia Blue Printed Kurta Set",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 1500,
      color: "#78C0CB",
      fabric: "Fabric - Polycotton",
      size: "L",
      design: "",
      sku: "CAS0015D",
      description: "",
      image: "../images/models/img-38-01.jpg",
      imagePath: ["../images/models/img-38-01.jpg"],
    },
    {
      id: 39,
      title: "Pink Striped Straight Kurta & Pants Set",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 899,
      color: "#DFD7D8",
      fabric: "Fabric - Cotton",
      size: "M",
      design: "",
      sku: "CAS0016D",
      description: "",
      image: "../images/models/img-39-01.jpg",
      imagePath: ["../images/models/img-39-01.jpg"],
    },
    {
      id: 40,
      title: "Art Silk Print Straight Kurta Pant Set",
      material: "",
      price: 8999,
      discountPrice: 6999,
      sellingPrice: 899,
      color: "#335E6C",
      fabric: "Fabric - Art Silk",
      size: "M",
      design: "",
      sku: "CAS0017D",
      description: "",
      image: "../images/models/img-40-01.jpg",
      imagePath: ["../images/models/img-40-01.jpg"],
    },
  ],
};

export const footerNavLinks = [
  {
    id: 1,
    webUrl: "/privacy-policy",
    title: "Privacy Policy",
  },
  {
    id: 2,
    webUrl: "/terms-&-conditions",
    title: "Terms & Conditions",
  },
  {
    id: 3,
    webUrl: "/exchange-policy",
    title: "Exchange Policy",
  },
];

export const sliderData = [
  {
    id: 1,
    image: "./images/banner/EthnicWebsiteBanner-01.jpg",
    altText: "Homepage Banner",
  },
  {
    id: 2,
    image: "./images/banner/EthnicWebsiteBanner-02.jpg",
    altText: "Homepage Banner",
  },
];

export const testimonials = [
  {
    name: "John Doe",
    text: "This product is amazing! Highly recommend it to everyone.",
    role: "CEO, Example Company",
    img: "",
  },
  {
    name: "Jane Smith",
    text: "Great customer service and top-notch quality!",
    role: "Marketing Head, Another Company",
    img: "",
  },
  {
    name: "Michael Johnson",
    text: "I had an awesome experience with this brand.",
    role: "Developer, Tech Corp",
    img: "",
  },
];

export const exhibitionVideos = [
  {
    id: 1,
    urlVideo: "../images/exhibition-videos/exhibition-01.mp4",
  },
  {
    id: 2,
    urlVideo: "../images/exhibition-videos/exhibition-02.mp4",
  },
  {
    id: 3,
    urlVideo: "../images/exhibition-videos/exhibition-03.mp4",
  },
];
