import React from "react";
import Slider from "react-slick";

const SlickCarousel = ({ sliderData }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {sliderData.map((item) => (
          <div key={item.id}>
            <img
              src={item.image}
              alt={item.altText}
              style={{ width: "100%", height: "auto" }}
              className="m-0"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SlickCarousel;
