import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Product from "../../components/product";
import { productData } from "../../data";

const NotFound = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts(productData.data);
  }, []);
  return (
    <>
      <section className="not-found-page">
        <div className="not-found-page-block">
          <div className="our-container">
            <div className="inner-container">
              <div className="content-page-details">
                <div className="not-found-img-block">
                  <img src="./images/Urban-Outfitters-GIF.webp" alt="" />
                </div>
                <div className="not-found-content-block">
                  <div className="d-grid gap-3 not-found-content-block-row">
                    {products.slice(0, 2).map((product) => (
                      <Product key={product.id} product={product} />
                    ))}
                  </div>
                  <div className="d-flex align-items-center justify-content-end mt-2">
                    <span>
                      <Link
                        to={"/"}
                        className="cart-continue-shopping-link"
                      >
                        <MdKeyboardArrowLeft className="left-arrow-cart-cs" />
                        <p className="mb-0">Continue shopping</p>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
