import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaShoppingCart, FaEdit } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import { useCart } from "../../contexts/CartContext";
import { FaFacebook, FaSquareInstagram } from "react-icons/fa6";

const Header = () => {
  const { cartItems } = useCart(); // Get cartItems from context

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const sidebarRef = useRef(null);
  const cartCount = cartItems.length; // Get cart count directly from context

  const handleSidebarToggle = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <header className={isFixed ? "fixed-header" : ""}> */}
      <header>
        <div className="py-1 header-top"></div>
        <div className="upper-menu-block">
          <div className="our-container">
            <div className="inner-container d-flex align-items-center justify-content-between mx-auto px-5">
              <div className="logo-block">
                <Link to={"/"} className="d-block">
                  <img src="/images/ethinic-logo-with-title.png" alt="" />
                </Link>
              </div>
              {/* <div className="mobile-icon-responsive">
                <GiHamburgerMenu
                  className="hamburgerMenu"
                  onClick={handleSidebarToggle}
                />
                <div className="">
                  <NavLink
                    to={"/cart"}
                    className="position-relative mobile-cart-block"
                    onClick={() => setSidebarToggle(false)}
                  >
                    <FaShoppingCart className="mobile-shop-cart" />
                  </NavLink>
                </div>
              </div> */}
              <div
                className={`main-menu ${sidebarToggle ? "sidebar-active" : ""}`}
                ref={sidebarRef}
              >
                {/* <div className="sidebar-top-navbar">
                  <div className="logo-block my-2">
                    <Link
                      to={"/"}
                      className="d-block"
                    //   onClick={() => setSidebarToggle(false)}
                    >
                      <img src="/images/karv-logo.png" alt="" />
                    </Link>
                  </div>
                  <RxCross2
                    className="sidebar-close-cross"
                    // onClick={() => setSidebarToggle(false)}
                  />
                </div> */}
                <nav>
                  <ul className="p-0 d-flex align-items-center justify-content-center mb-0 nav-links">
                    <li>
                      <NavLink to={"/"} className="fs-6 fw-semibold nav-items">
                        Products
                      </NavLink>
                    </li>
                    <li className="mobile-pc-cart">
                      <Link
                        to={
                          "https://www.facebook.com/people/Ethnicindi/61552992852504/?mibextid=ZbWKwL"
                        }
                        target="_blank"
                        className="fs-6 fw-semibold nav-items shop-cart-label"
                      >
                        <FaFacebook className="fs-6 d-flex align-items-center justify-content-center fs-link-item" />
                      </Link>
                    </li>
                    <li className="mobile-pc-cart">
                      <Link
                        to={
                          "https://www.instagram.com/ethnicindi_/?utm_source=qr&igsh=YjkwN2J4cXl2bGVj"
                        }
                        target="_blank"
                        className="fs-6 fw-semibold nav-items shop-cart-label"
                      >
                        <FaSquareInstagram className="fs-6 d-flex align-items-center justify-content-center fs-link-item" />
                      </Link>
                    </li>
                    <li className="mobile-pc-cart">
                      <NavLink
                        to={"/cart"}
                        className="fs-6 fw-semibold nav-items shop-cart-label"
                        onClick={() => setSidebarToggle(false)}
                      >
                        <FaShoppingCart className="shop-cart" />
                        <span
                          className={`${cartCount > 0 && "c-value-mobile"}`}
                        >
                          {cartCount > 0 && `${cartCount}`}
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
