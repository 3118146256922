import React from "react";
import MetaDescTag from "../../components/MetaDescTag";

const TermsAndConditions = () => {
  return (
    <>
      <MetaDescTag
        title={`TERMS AND CONDITIONS | EthnicINDI`}
        description={`These terms and conditions outline the rules and regulations for the use of ethnicindi.com Website, located at https://ethnicindi.com/ By accessing this website, we assume you accept these terms and conditions.`}
      />
      <section className="py-5 tnc-page">
        <div className="our-container">
          <div className="inner-container mx-auto">
            <div className="mb-4 tnc-page-heading">
              <h1 className="fs-2 fw-medium text-center text-uppercase">
                TERMS AND CONDITIONS
              </h1>
            </div>
            <div className="tnc-page-body">
              <div className="mb-4 tnc-greeting">
                <h2 className="fs-5 fw-medium text-center text-uppercase">
                  "WELCOME TO{" "}
                  <span className="text-lowercase">ethnicindi.com</span>"
                </h2>
                <div className="tnc-greeting-body">
                  <p className="fs-6 text-capitalize mb-2">
                    These terms and conditions outline the rules and regulations
                    for the use of{" "}
                    <span className="text-lowercase">ethnicindi.com</span>{" "}
                    Website, located at{" "}
                    <span className="text-lowercase">
                      https://ethnicindi.com/
                    </span>
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    By continuing to use{" "}
                    <span className="text-lowercase">ethnicindi.com</span>, you
                    agree to our terms and conditions, ensuring a smooth and
                    enjoyable experience on our platform.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    The following terminology applies to these Terms and
                    Conditions, Privacy Statement and Disclaimer Notice and all
                    Agreements: "Client", "You" and "Your" refers to you, the
                    person log on this website and compliant to the Company's
                    terms and conditions. "The Company", "Ourselves", "We",
                    "Our" and "Us", refers to our Company. "Party", "Parties",
                    or "Us", refers to both the Client and ourselves. All terms
                    refer to the offer, acceptance and consideration of payment
                    necessary to undertake the process of our assistance to the
                    Client in the most appropriate manner for the express
                    purpose of meeting the Client's needs in respect of
                    provision of the Company's stated services, in accordance
                    with and subject to, prevailing law of in. Any use of the
                    above terminology or other words in the singular, plural,
                    capitalization and/or he/she or they, are taken as
                    interchangeable and therefore as referring to same
                  </p>
                </div>
              </div>
              <div className="mb-4 tnc-cookies">
                <div className="h4 fs-5 fw-medium text-left text-uppercase">
                  COOKIES
                </div>
                <div className="mb-3 tnc-cookies-body">
                  <p className="fs-6 text-capitalize mb-2">
                    We employ the use of cookies. By accessing{" "}
                    <span className="text-lowercase">ethnicindi.com</span>, you
                    agreed to use cookies in agreement with the{" "}
                    <span className="text-lowercase">ethnicindi.com</span>{" "}
                    Privacy Policy.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    Most interactive websites use cookies to let us retrieve the
                    user's details for each visit. Cookies are used by our
                    website to enable the functionality of certain areas to make
                    it easier for people visiting our website. Some of our
                    affiliate/advertising partners may also use cookies.
                  </p>
                </div>
              </div>
              <div className="mb-4 tnc-license">
                <div className="h4 fs-5 fw-medium text-left text-uppercase">
                  LICENSE
                </div>
                <div className="mb-3 tnc-license-body">
                  <p className="fs-6 text-capitalize mb-2">
                    Unless otherwise stated,{" "}
                    <span className="text-lowercase">ethnicindi.com</span>{" "}
                    and/or its licensors own the intellectual property rights
                    for all material on{" "}
                    <span className="text-lowercase">ethnicindi.com</span>. All
                    intellectual property rights are reserved. You may access
                    this from{" "}
                    <span className="text-lowercase">ethnicindi.com</span> for
                    your own personal use subjected to restrictions set in these
                    terms and conditions.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">You must not:</p>
                  <ul>
                    <li>
                      Republish material from{" "}
                      <span className="text-lowercase">ethnicindi.com</span>
                    </li>
                    <li>
                      Sell, rent or sub-license material from{" "}
                      <span className="text-lowercase">ethnicindi.com</span>
                    </li>
                    <li>
                      Reproduce, duplicate or copy material from{" "}
                      <span className="text-lowercase">ethnicindi.com</span>
                    </li>
                    <li>
                      Redistribute content from{" "}
                      <span className="text-lowercase">ethnicindi.com</span>
                    </li>
                  </ul>
                  <p className="fs-6 text-capitalize mb-2">
                    Parts of this website offer an opportunity for users to post
                    and exchange opinions and information in certain areas of
                    the website.{" "}
                    <span className="text-lowercase">ethnicindi.com</span> does
                    not filter, edit, publish or review Comments prior to their
                    presence on the website. Comments do not reflect the views
                    and opinions of
                    <span className="text-lowercase"> ethnicindi.com</span>, its
                    agents and/or affiliates. Comments reflect the views and
                    opinions of the person who post their views and opinions. To
                    the extent permitted by applicable laws,
                    <span className="text-lowercase"> ethnicindi.com</span>{" "}
                    shall not be liable for the Comments or for any liability,
                    damages or expenses caused and/or suffered as a result of
                    any use of and/or posting of and/or appearance of the
                    Comments on this website.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    <span className="text-lowercase">ethnicindi.com</span>{" "}
                    reserves the right to monitor all Comments and to remove any
                    Comments which can be considered inappropriate, offensive or
                    causes breach of these Terms and Conditions.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    You warrant and represent that:
                  </p>
                  <ul className="text-capitalize">
                    <li>
                      You are entitled to post the Comments on our website and
                      have all necessary licenses and consents to do so.
                    </li>
                    <li>
                      The Comments do not invade any intellectual property
                      right, including without limitation copyright, patent or
                      trademark of any third party.
                    </li>
                    <li>
                      The Comments do not contain any defamatory, libelous,
                      offensive, indecent or otherwise unlawful material which
                      is an invasion of privacy.
                    </li>
                    <li>
                      The Comments will not be used to solicit or promote
                      business or custom or present commercial activities or
                      unlawful activity.
                    </li>
                    <li>
                      You hereby grant{" "}
                      <span className="text-lowercase">ethnicindi.com</span> a
                      non-exclusive license to use, reproduce, edit and
                      authorize others to use, reproduce and edit any of your
                      Comments in any and all forms, formats or media.
                    </li>
                    <li>Hyperlinking to our Content.</li>
                  </ul>
                  <p className="fs-6 text-capitalize mb-2">
                    The following organizations may link to our Website without
                    prior written approval:
                  </p>
                  <ul className="text-capitalize">
                    <li>Government agencies.</li>
                    <li>Search engines.</li>
                    <li>News organizations.</li>
                  </ul>
                  <p className="fs-6 text-capitalize mb-2">
                    Online directory distributors may link to our Website in the
                    same manner as they hyperlink to the Websites of other
                    listed businesses; and System wide Accredited Businesses
                    except soliciting non-profit organizations, charity shopping
                    malls, and charity fundraising groups which may not
                    hyperlink to our Web site.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    These organizations may link to our home page, to
                    publications or to other Website information so long as the
                    link: (a) is not in any way deceptive; (b) does not falsely
                    imply sponsorship, endorsement or approval of the linking
                    party and its products and/or services; and (c) fits within
                    the context of the linking party's site.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    We may consider and approve other link requests from the
                    following types of organizations:
                  </p>
                  <ul className="text-capitalize">
                    <li>
                      commonly-known consumer and/or business information
                      sources.
                    </li>
                    <li>
                      <span className="text-lowercase">dot.com</span> community
                      sites.
                    </li>
                    <li>
                      associations or other groups representing charities.
                    </li>
                    <li>online directory distributors.</li>
                    <li>internet portals.</li>
                    <li>
                      accounting, law and consulting firms and educational
                      institutions and trade associations.
                    </li>
                  </ul>
                  <p className="fs-6 text-capitalize mb-2">
                    We will approve link requests from these organizations if we
                    decide that: (a) the link would not make us look unfavorably
                    to ourselves or to our accredited businesses; (b) the
                    organization does not have any negative records with us; (c)
                    the benefit to us from the visibility of the hyperlink
                    compensates the absence of{" "}
                    <span className="text-lowercase">ethnicindi.com</span>; and
                    (d) the link is in the context of general resource
                    information.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    These organizations may link to our home page so long as the
                    link: (a) is not in any way deceptive; (b) does not falsely
                    imply sponsorship, endorsement or approval of the linking
                    party and its products or services; and (c) fits within the
                    context of the linking party's site.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    If you are one of the organizations listed in paragraph 2
                    above and are interested in linking to our website, you must
                    inform us by sending an e-mail to{" "}
                    <span className="text-lowercase">ethnicindi.com</span>.
                    Please include your name, your organization name, contact
                    information as well as the URL of your site, a list of any
                    URLs from which you intend to link to our Website, and a
                    list of the URLs on our site to which you would like to
                    link. Wait 2-3 weeks for a response.
                  </p>
                  <p className="fs-6 text-capitalize mb-2">
                    Approved organizations may hyperlink to our Website as
                    follows:
                  </p>
                  <ul>
                    <li>By use of our corporate name or</li>
                    <li>
                      By use of the uniform resource locator being linked to or
                    </li>
                    <li>
                      By use of any other description of our Website being
                      linked to that makes sense within the context and format
                      of content on the linking party's site.
                    </li>
                    <li>
                      No use of ethnicindi.comlogo or other artwork will be
                      allowed for linking absent a trademark license agreement.
                    </li>
                  </ul>
                </div>
                <div className="mb-4 tnc-iFrames">
                  <div className="h4 fs-5 fw-medium text-left text-uppercase">
                    iFrames
                  </div>
                  <div className="mb-3 tnc-iFrames-body">
                    <p className="fs-6 text-capitalize mb-2">
                      Without prior approval and written permission, you may not
                      create frames around our Webpages that alter in any way
                      the visual presentation or appearance of our Website.
                    </p>
                  </div>
                </div>
                <div className="mb-4 tnc-content-liability">
                  <div className="h4 fs-5 fw-medium text-left text-uppercase">
                    Content Liability
                  </div>
                  <div className="mb-3 tnc-content-liability-body">
                    <p className="fs-6 text-capitalize mb-2">
                      We shall not be hold responsible for any content that
                      appears on your Website. You agree to protect and defend
                      us against all claims that is rising on your Website. No
                      link(s) should appear on any Website that may be
                      interpreted as libelous, obscene or criminal, or which
                      infringes, otherwise violates, or advocates the
                      infringement or other violation of, any third party
                      rights.
                    </p>
                  </div>
                </div>
                <div className="mb-4 tnc-reservation-right">
                  <div className="h4 fs-5 fw-medium text-left text-uppercase">
                    Reservation of Rights
                  </div>
                  <div className="mb-3 tnc-reservation-right-body">
                    <p className="fs-6 text-capitalize mb-2">
                      We reserve the right to request that you remove all links
                      or any particular link to our Website. You approve to
                      immediately remove all links to our Website upon request.
                    </p>
                    <p className="fs-6 text-capitalize mb-2">
                      We also reserve the right to amen these terms and
                      conditions and it's linking policy at any time. By
                      continuously linking to our Website, you agree to be bound
                      to and follow these linking terms and conditions.
                    </p>
                  </div>
                </div>
                <div className="mb-4 tnc-links-web">
                  <div className="h4 fs-5 fw-medium text-left text-uppercase">
                    Removal of links from our website
                  </div>
                  <div className="mb-3 tnc-links-web-body">
                    <p className="fs-6 text-capitalize mb-2">
                      If you find any link on our Website that is offensive for
                      any reason, you are free to contact and inform us any
                      moment. We will consider requests to remove links but we
                      are not obligated to or so or to respond to you directly.
                    </p>
                    <p className="fs-6 text-capitalize mb-2">
                      We do not ensure that the information on this website is
                      correct, we do not warrant its completeness or accuracy;
                      nor do we promise to ensure that the website remains
                      available or that the material on the website is kept up
                      to date.
                    </p>
                  </div>
                </div>
                <div className="mb-4 tnc-disclaimer">
                  <div className="h4 fs-5 fw-medium text-left text-uppercase">
                    Disclaimer
                  </div>
                  <div className="mb-3 tnc-disclaimer-body">
                    <p className="fs-6 text-capitalize mb-2">
                      To the maximum extent permitted by applicable law, we
                      exclude all representations, warranties and conditions
                      relating to our website and the use of this website.
                      Nothing in this disclaimer will:
                    </p>
                    <ul className="text-capitalize">
                      <li>
                        limit or exclude our or your liability for death or
                        personal injury.
                      </li>
                      <li>
                        limit or exclude our or your liability for fraud or
                        fraudulent misrepresentation.
                      </li>
                      <li>
                        limit any of our or your liabilities in any way that is
                        not permitted under applicable law or
                      </li>
                    </ul>
                    <p className="fs-6 text-capitalize mb-2">
                      exclude any of our or your liabilities that may not be
                      excluded under applicable law.
                    </p>
                    <p className="fs-6 text-capitalize mb-2">
                      The limitations and prohibitions of liability set in this
                      Section and elsewhere in this disclaimer: (a) are subject
                      to the preceding paragraph; and (b) govern all liabilities
                      arising under the disclaimer, including liabilities
                      arising in contract, in tort and for breach of statutory
                      duty.
                    </p>
                    <p className="fs-6 text-capitalize mb-2">
                      As long as the website and the information and services on
                      the website are provided free of charge, we will not be
                      liable for any loss or damage of any nature.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
