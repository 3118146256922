import React from "react";
import Slider from "react-slick";
import { testimonials } from "../../data";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="testimonial-container">
      <div className="h3">What Our Clients Say</div>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-slide">
          <div className="testimonial-img-container">
            <img src={testimonial.img} alt="ggggggg" />
          </div>
            <p>"{testimonial.text}"</p>
            <h4>
              - {testimonial.name}, {testimonial.role}
            </h4>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonial;
